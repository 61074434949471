import { Stack } from "@mui/material";
import { GenericDataGrid } from "../../generic";
import { useFilterableDataGridContext } from "../context";

function FilteredDataGrid(): JSX.Element {
    const {
        apiRef,
        columns,
        columnVisibilityModel,
        filterButtons,
        initialStatePagination,
        initialStateSorting,
        pageSizeOptions,
        rows,
        slots,
        onColumnVisibilityModelChange
    } = useFilterableDataGridContext();

    return (
        <Stack
            direction="column"
            spacing={2}
            >
            <Stack
                direction="row"
                spacing={2}
                >
                {filterButtons}
            </Stack>
            <GenericDataGrid
                apiRef={apiRef}
                columns={columns}
                columnVisibilityModel={columnVisibilityModel}
                initialState={{
                    pagination: {
                        paginationModel: initialStatePagination,
                    },
                    sorting: initialStateSorting,
                }}
                onColumnVisibilityModelChange={onColumnVisibilityModelChange}
                pageSizeOptions={pageSizeOptions}
                rows={rows}
                slots={slots}
                />
        </Stack>
    );
}

export { FilteredDataGrid }