import React from "react";
import { useApiService, useAuth } from "../..";
import { featureFlagInitialState, FeatureFlagReducer } from "./reducer";
import { FeatureFlagResult, GetFeatureFlagResultResponse } from "types/admin/featureFlags";

type FeatureFlagContextType = {
    canUseDsp: boolean
    canUseDspAudits: boolean
    canFindDspAuditSupersessions: boolean
    canUseDsv: boolean
}

const FeatureFlagContext = React.createContext<FeatureFlagContextType | undefined>(undefined);

type FeatureFlagProviderProps = {
    children: React.ReactNode
}

function FeatureFlagProvider({ children }: FeatureFlagProviderProps): JSX.Element {
    const { user } = useAuth();
    const { apiService } = useApiService();
    const [state, dispatch] = React.useReducer(FeatureFlagReducer, featureFlagInitialState);

    const findRecordCallback = React.useCallback((): FeatureFlagResult | undefined => {
        return state.records.at(0);
    }, [state.records]);

    const canUseDspMemo = React.useMemo((): boolean => {
        const foundRecord = findRecordCallback();
        return foundRecord !== undefined && foundRecord.canUseDsp;
    }, [findRecordCallback]);

    const canUseDspAuditsMemo = React.useMemo((): boolean => {
        const foundRecord = findRecordCallback();
        return foundRecord !== undefined && foundRecord.canUseDspAudits;
    }, [findRecordCallback]);

    const canUseFindDspAuditSupersessionsMemo = React.useMemo((): boolean => {
        const foundRecord = findRecordCallback();
        return foundRecord !== undefined && foundRecord.canFindDspAuditSupersessions;
    }, [findRecordCallback]);

    const canUseDsvMemo = React.useMemo((): boolean => {
        const foundRecord = findRecordCallback();
        return foundRecord !== undefined && foundRecord.canUseDsv;
    }, [findRecordCallback]);

    React.useEffect(() => {
        if (!user) {
            return;
        }

        apiService.featureFlag.get()
            .then((response: GetFeatureFlagResultResponse) => dispatch({ type: "SET_RECORDS", payload: response }))
            .catch(err => {
                console.error("Unable to get record for user.", err);
            });
    }, [apiService, user]);

    return (
        <FeatureFlagContext.Provider
            value={{
                canUseDsp: canUseDspMemo,
                canUseDspAudits: canUseDspAuditsMemo,
                canFindDspAuditSupersessions: canUseFindDspAuditSupersessionsMemo,
                canUseDsv: canUseDsvMemo,
            }}
            >
            {children}
        </FeatureFlagContext.Provider>
    );
}

function useFeatureFlagContext(): FeatureFlagContextType {
    const context = React.useContext(FeatureFlagContext);

    if (!context) {
        throw new Error("useFeatureFlagContext must be used within a FeatureFlagProvider");
    }

    return context;
}

export { FeatureFlagProvider, useFeatureFlagContext }