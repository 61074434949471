import { AxiosInstance } from "axios";
import { ApiService } from "./apiService";
import { CreatePrintServiceLocationRequest, CreatePrintServiceLocationResponse, GetPrintServiceLocationResponse, UpdatePrintServiceLocationRequest } from "types/print/printServiceLocation";

export class PrintServiceLocationService extends ApiService {
    super(api: AxiosInstance) { }

    getAll(): Promise<GetPrintServiceLocationResponse[]> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.get("/api/printservicelocation/all");
    
                const getAllPrintServiceLocationResponse = response.data as GetPrintServiceLocationResponse[];
                resolve(getAllPrintServiceLocationResponse);
            } catch (err) {
                reject(err);
            }
        });
    }

    getOne(): Promise<GetPrintServiceLocationResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.get("/api/printservicelocation");
    
                const getPrintServiceLocationResponse = response.data as GetPrintServiceLocationResponse;
                resolve(getPrintServiceLocationResponse);
            } catch (err) {
                reject(err);
            }
        });
    }

    create(printTemplateId: number): Promise<number> {
        return new Promise(async (resolve, reject) => {
            try {
                const requestBody: CreatePrintServiceLocationRequest = {
                    printTemplateId
                };

                const response = await this.api.post("/api/printservicelocation", requestBody);

                const createPrintServiceLocationResponse = response.data as CreatePrintServiceLocationResponse;
                resolve(createPrintServiceLocationResponse.id);
            } catch (err) {
                reject(err);
            }
        });
    }

    update(printTemplateId: number): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const requestBody: UpdatePrintServiceLocationRequest = {
                    printTemplateId,
                };

                const response = await this.api.patch("/api/printservicelocation", requestBody);
                
                if (response.status !== 200) {
                    throw new Error(`Unexpected status code: ${response.status}`);
                }

                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    delete(id: number): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.delete(`/api/printservicelocation?id=${id}`);
                
                if (response.status !== 200) {
                    throw new Error(`Unexpected status code: ${response.status}`);
                }

                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }
}