import React from "react";
import { useDspAuditVarianceContext } from "../context";
import { MismatchDataGrid } from "./mismatch";
import { VarianceDataGrid } from "./variance";

function VarianceDataGridResolver(): JSX.Element {
    const { selectedTab } = useDspAuditVarianceContext();
    const [component, setComponent] = React.useState<JSX.Element>(<></>);

    React.useEffect(() => {
        setComponent(() => {
            switch (selectedTab) {
                case "pending":
                    return <VarianceDataGrid />;
                case "rescans":
                    return <VarianceDataGrid />;
                case "mismatch":
                    return <MismatchDataGrid />;
                default: 
                    return (<></>);
            }
        });
    }, [selectedTab]);

    return (component);
}

export { VarianceDataGridResolver }