import React from "react";
import { useApiService } from "../../../base/providers";
import { dsvHomeInitialState, DsvHomeReducer } from "./reducer";
import { VehicleAudit } from "types/dsv/audit";

type DsvHomeContextType = {
    vehicleAudits: VehicleAudit[]
    deleteVehicleAudit: (deleteId: number) => Promise<void>
    reloadData: () => void
}

const DsvHomeContext = React.createContext<DsvHomeContextType | undefined>(undefined);

type DsvHomeContextProviderProps = {
    children: React.ReactNode
}

export function DsvHomeContextProvider(props: DsvHomeContextProviderProps): JSX.Element {
    const { apiService } = useApiService();
    const [state, dispatch] = React.useReducer(DsvHomeReducer, dsvHomeInitialState);

    const getAllVehicleAuditsCallback = React.useCallback(() => {
        apiService.dsv.getAllVehicleAudits()
            .then(vehicleAudits => dispatch({ type: "SET_VEHICLE_AUDITS", payload: vehicleAudits }))
            .catch(err => console.error("Unable to get all vehicle audits", err));
    }, [apiService]);

    const deleteVehicleAuditCallback = React.useCallback((deleteId: number): Promise<void> => {
        return new Promise((resolve, reject) => {
            apiService.dsv.deleteVehicleAudit(deleteId)
                .then(() => getAllVehicleAuditsCallback())
                .then(() => resolve())
                .catch((err) => reject(err));
        });
    }, [apiService, getAllVehicleAuditsCallback]);

    React.useEffect(() => {
        getAllVehicleAuditsCallback();
    }, [getAllVehicleAuditsCallback]);

    return (
        <DsvHomeContext.Provider
            value={{
                vehicleAudits: state.vehicleAudits,
                deleteVehicleAudit: deleteVehicleAuditCallback,
                reloadData: getAllVehicleAuditsCallback
            }}
            >
            {props.children}
        </DsvHomeContext.Provider>
    );
}

export function useDsvHomeContext(): DsvHomeContextType {
    const context = React.useContext(DsvHomeContext);

    if (!context) {
        throw new Error("useDsvHomeContext must be used within a DsvHomeContextProvider");
    }

    return context;
}