import React from 'react';
import ReactDOM from 'react-dom/client';
import { createTheme, ThemeProvider } from '@mui/material';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import TagManager from "react-gtm-module";

import CssBaseline from '@mui/material/CssBaseline';

const theme = createTheme({
  palette: {
    primary: {
      main: "#0A2E49"
    }
  }
});

TagManager.initialize({
  gtmId: process.env.REACT_APP_GTM_ID || "",
  auth: process.env.REACT_APP_GTM_AUTH || "",
  preview: process.env.REACT_APP_GTM_PREVIEW || "",
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
