import { AcceptPendingVarianceButton } from "./acceptPendingVarianceButton";
import { OptionsResolver } from "./optionsResolver";
import { Box, Stack } from "@mui/material";
import { VarianceTabs } from "./tabs";

function VarianceOptionsRow(): JSX.Element {
    return (
        <Stack direction="row">
            <VarianceTabs />
            <Box flexGrow={1} />
            <OptionsResolver />
        </Stack>
    );
}

export { AcceptPendingVarianceButton, VarianceOptionsRow }