import { Box, Typography } from "@mui/material";
import Barcode from "react-barcode";
import { TemplateProps } from "../../props";

export function FiveByFourTopForm({ vehicle }: TemplateProps): JSX.Element {
    return (
        <Box className="labelVinTopForm">
            <Typography
                className="labelVinStockNumber"
                >
                {vehicle.stockNumber}
            </Typography>
            <Box className="labelVinBarcode">
                <Barcode
                    displayValue={false}
                    height={100}
                    renderer="svg"
                    value={vehicle.vin}
                    width={1.0}
                    />
            </Box>
            <Box className="labelVinDescriptionContainer">
                <Typography align="left" className="labelVinIdentificationNumber">
                    {vehicle.vin}
                </Typography>
                <Typography align="left" className="labelVinDescription">
                    {`${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.bodyStyle} ${vehicle.color}`}
                </Typography>
            </Box>
        </Box>
    );
}