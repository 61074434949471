import { VehicleAudit } from "types/dsv/audit";

type DsvHomeReducerState = {
    vehicleAudits: VehicleAudit[]
}

type DsvHomeReducerAction =
    | { type: "SET_VEHICLE_AUDITS", payload: VehicleAudit[] }

export const dsvHomeInitialState: DsvHomeReducerState = {
    vehicleAudits: []
};

export function DsvHomeReducer(state: DsvHomeReducerState, action: DsvHomeReducerAction): DsvHomeReducerState {
    switch (action.type) {
        case "SET_VEHICLE_AUDITS":
            return {
                ...state,
                vehicleAudits: action.payload
            };
    }
}