import { Box, Typography } from "@mui/material";
import Barcode from "react-barcode";
import { TemplateProps } from "../../props";

export function FiveByFourBottomForm({ vehicle }: TemplateProps): JSX.Element {
    return (
        <Box className="labelVinBottomForm">
            <Box className="labelVinBottomLeft">
                <Typography
                    align="left"
                    className="labelVinStockNumber"
                    >
                    {vehicle.stockNumber}
                </Typography>
                <Box className="labelVinBarcode">
                    <Barcode
                        displayValue={false}
                        height={35}
                        renderer="svg"
                        value={vehicle.vin}
                        width={0.5}
                        />
                </Box>
                <Typography
                    align="left"
                    className="labelVinIdentificationNumber"
                    >
                    {vehicle.vin}
                </Typography>
            </Box>
            <Box className="labelVinBottomRight">
                <Typography className="labelVinBottomDescription">
                    {`${vehicle.model} ${vehicle.make} ${vehicle.year}`}
                </Typography>
            </Box>
        </Box>
    );
}