import React from "react";
import { DsvPrinter } from "../../../../types/dsv/setup";
import { GridColDef } from "@mui/x-data-grid";
import { useApiService } from "../../../base/providers";
import { DeleteButton, EditButton } from "../../../base/crud";
import { EditPrinterForm, EditPrinterFormValues } from "./editPrinterForm";
import { DsvPrinterType } from "../../../../types/dsv/printerTypes";
import { GenericModal } from "../../../base/modal";
import { GenericDataGrid } from "../../../base/dataGrid";

type PrinterSetupDataGridProps = {
    rows: DsvPrinter[]
    reloadDataCallback: () => void
}

type MappedRow = {
    id: number
    name: string
    printerType: number
    host: string
    editId: number
    deleteId: number
}

function PrinterSetupDataGrid(props: PrinterSetupDataGridProps): JSX.Element {
    const { rows, reloadDataCallback } = props;
    const { apiService } = useApiService();
    const [showEditModal, setShowEditModal] = React.useState(false);
    const [editPrinterId, setEditPrinterId] = React.useState(-1);
    const [mappedRows, setMappedRows] = React.useState<MappedRow[]>([]);

    const hideEditModalCallback = React.useCallback(() => {
        setShowEditModal(false);
    }, []);

    const editActionCallback = React.useCallback((editId: number): void => {
        setEditPrinterId(editId);
        setShowEditModal(true);
    }, []);

    const editClickSubmitCallback = React.useCallback((formValues: EditPrinterFormValues): void => {
        const printerTypeNum = Number(formValues.printerType);
        
        if (isNaN(printerTypeNum) || printerTypeNum === 0) {
            console.error("Printer type is not valid", formValues.printerType);
            return;
        }

        apiService.dsv.updatePrinter(formValues.id, formValues.printerName, printerTypeNum, formValues.host)
            .then(() => setShowEditModal(false))
            .then(() => reloadDataCallback())
            .catch((err: any) => console.error("An error occurred while submitting edit to printer", err));
    }, [apiService, reloadDataCallback]);

    const deleteActionCallback = React.useCallback((deleteId: number): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            try {
                await apiService.dsv.deletePrinter(deleteId);
                reloadDataCallback();
                resolve();
            } catch (err) {
                reject(err);
            }
        })
    }, [apiService, reloadDataCallback]);

    React.useEffect(() => {
        if (!rows) {
            return;
        }

        setMappedRows(rows.map((value: DsvPrinter): MappedRow => {
            return {
                id: value.id,
                name: value.name,
                printerType: value.printerType,
                host: value.host,
                editId: value.id,
                deleteId: value.id
            }
        }));
    }, [rows])

    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "ID",
            hideable: false,
            width: 90
        },
        {
            field: "name",
            flex: 1,
            headerName: "Name",
            hideable: false
        },
        {
            field: "printerType",
            flex: 1,
            headerName: "Printer Type",
            hideable: false,
            renderCell: (params) => (
                <span>{DsvPrinterType.mapValue(params.value)}</span>
            )
        },
        {
            field: "host",
            flex: 1,
            headerName: "Host",
            hideable: false
        },
        {
            field: "editId",
            filterable: false,
            headerName: "",
            hideable: false,
            renderCell: (params) => (
                <EditButton
                    editId={params.value}
                    editAction={editActionCallback}
                    />
            ),
            sortable: false,
            width: 100
        },
        {
            field: "deleteId",
            filterable: false,
            headerName: "",
            hideable: false,
            renderCell: (params) => (
                <DeleteButton
                    deleteId={params.value}
                    deleteAction={deleteActionCallback}
                    />
            ),
            sortable: false,
            width: 100
        }
    ];

    return (
        <>
            <GenericModal
                showModal={showEditModal}
                hideModalCallback={hideEditModalCallback}
                dialogTitle="Edit Printer"
                >
                <EditPrinterForm
                    id={editPrinterId}
                    clickSubmitCallback={editClickSubmitCallback}
                    />
            </GenericModal>
            <GenericDataGrid
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            page: 0,
                            pageSize: 25,
                        }
                    }
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                rows={mappedRows}
                />
        </>
        
    );
}

export { PrinterSetupDataGrid }