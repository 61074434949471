import React from "react";
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';

type WritebackRadioButtonIconProps = {
    value: boolean
}

export function WritebackRadioButtonIcon({ value }: WritebackRadioButtonIconProps): JSX.Element {
    const [icon, setIcon] = React.useState(<RadioButtonUncheckedOutlinedIcon />);

    React.useEffect(() => {
        setIcon(() => {
            switch(value) {
                case true:
                    return <RadioButtonCheckedOutlinedIcon />;
                case false:
                    return <RadioButtonUncheckedOutlinedIcon />
            }
        })
    }, [value]);
    
    return (icon);
}