import { VehicleAuditsDataGrid } from "./dataGrid";
import { Stack, Typography } from "@mui/material";
import { VehiclesNav } from "./nav";
import { DsvHomeContextProvider } from "./context";

export function AllVehicleAudits(): JSX.Element {
    return (
        <DsvHomeContextProvider>
            <Stack
                direction="column"
                spacing={2}
                >
                <Stack direction="row">
                    <Typography
                        flexGrow={1}
                        variant="h6"
                        >
                        Vehicle Scan History
                    </Typography>
                </Stack>
                <VehiclesNav />
                <VehicleAuditsDataGrid />
            </Stack>
        </DsvHomeContextProvider>
    );
}