import { Typography } from "@mui/material";
import { LabelBadge } from "./badge/labelBadge";
import { useDspAuditVarianceContext } from "../../../context";

export function BinMismatchesLabel(): JSX.Element {
    const { binMismatches } = useDspAuditVarianceContext();

    return (
        <LabelBadge value={binMismatches.length}>
            <Typography>Bin Mismatches</Typography>
        </LabelBadge>
    );
}