import React from "react";
import { useApiService } from "../../../../../../../base/providers";
import { createAuditInitialState, CreateAuditReducer } from "./reducer";

type CreateAuditContextType = {
    auditName: string,
    writebackAtCompletion: boolean,
    updateAuditName: (newAuditName: string) => void
    toggleWritebackAtCompletion: (boolVal: boolean) => void
    submitForm: () => Promise<number>
}

const CreateAuditContext = React.createContext<CreateAuditContextType | undefined>(undefined);

type DspAuditProviderProps = {
    children: React.ReactNode
}

function CreateAuditContextProvider(props: DspAuditProviderProps): JSX.Element {
    const { apiService } = useApiService();
    const [state, dispatch] = React.useReducer(CreateAuditReducer, createAuditInitialState);

    const updateAuditNameCallback = React.useCallback((newAuditName: string): void => {
        dispatch({ type: "UPDATE_NAME", payload: newAuditName });
    }, []);

    const toggleWritebackAtCompletionCallback = React.useCallback((boolVal: boolean): void => {
        dispatch({ type: "TOGGLE_WRITEBACK", payload: boolVal });
    }, []);

    const submitFormCallback = React.useCallback((): Promise<number> => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await apiService.dsp.createAudit(state.auditName, state.writebackAtCompletion);
                resolve(response.auditId);
            } catch (err) {
                reject(err);
            }
        })
    }, [apiService, state]);


    return (
        <CreateAuditContext.Provider
            value={{
                auditName: state.auditName,
                writebackAtCompletion: state.writebackAtCompletion,
                updateAuditName: updateAuditNameCallback,
                toggleWritebackAtCompletion: toggleWritebackAtCompletionCallback,
                submitForm: submitFormCallback
            }}
            >
            {props.children}
        </CreateAuditContext.Provider>
    );
}

function useCreateAuditContext(): CreateAuditContextType {
    const context = React.useContext(CreateAuditContext);

    if (!context) {
        throw new Error("useCreateAuditContext must be used within a CreateAuditContextProvider");
    }

    return context;
}

export { CreateAuditContextProvider, useCreateAuditContext }