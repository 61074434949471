import { Card, CardContent, Typography, Box } from "@mui/material";
import Barcode from "react-barcode";
import { TemplateProps } from "./props";

export function ThreeByTwoTemplate({ vehicle }: TemplateProps): JSX.Element {
    return (
        <Card
            sx={{
                height: "2in",
                width: "3in"
            }}
            >
            <CardContent className="labelVin">
                    <Typography className="labelVinStockNumber">
                        {vehicle.stockNumber}
                    </Typography>
                    <Box className="labelVinBarcode">
                        <Barcode
                            displayValue={false}
                            height={75}
                            renderer="svg"
                            value={vehicle.vin}
                            width={1.0}
                            />
                    </Box>
                    <Typography className="labelVinIdentificationNumber">
                        {vehicle.vin}
                    </Typography>
                    <Typography className="labelVinDescription">
                        {`${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.bodyStyle} ${vehicle.color}`}
                    </Typography>
            </CardContent>
        </Card>
    );
}