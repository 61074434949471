import { Box, Tab, Tabs } from "@mui/material";
import { PendingVariancesLabel } from "./labels/pendingVariances";
import { BinMismatchesLabel } from "./labels/binMismatches";
import { RescansLabel } from "./labels/rescans";
import { useDspAuditVarianceContext } from "../../context";
import { AuditVarianceTabType } from "../../types";

export function VarianceTabs(): JSX.Element {
    const { selectedTab, setSelectedTab } = useDspAuditVarianceContext();

    const onChangeSelectedTab = (e: React.SyntheticEvent, newValue: AuditVarianceTabType) => {
        setSelectedTab(newValue);
    };
    
    return (
        <Box
            sx={{
                borderBottom: 1,
                borderColor: "divider",
            }}
            >
            <Tabs
                onChange={onChangeSelectedTab}
                value={selectedTab}
                sx={{
                    "& .MuiTab-root": {
                        paddingRight: "42px",
                    }
                }}
                >
                <Tab
                    label={<PendingVariancesLabel />}
                    value="pending"
                    />
                <Tab
                    label={<RescansLabel />}
                    value="rescans"
                    />
                <Tab
                    label={<BinMismatchesLabel />}
                    value="mismatch"
                    />
            </Tabs>
        </Box>
    );
}