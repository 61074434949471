import React from "react";
import { useDspAuditVarianceContext } from "../../context";
import { MappedRow } from "./types";
import { columns } from "./columns";
import { DspBinMismatch } from "types/dsp/audits";
import { GenericDataGrid } from "../../../../../base/dataGrid";

export function MismatchDataGrid(): JSX.Element {
    const { binMismatches } = useDspAuditVarianceContext();
    const [mappedRows, setMappedRows] = React.useState<MappedRow[]>([]);

    React.useEffect(() => {
        if (!binMismatches) {
            return;
        }

        setMappedRows(binMismatches.map((value: DspBinMismatch, index: number) => {
            return {
                ...value,
                id: index,
            };
        }));
    }, [binMismatches]);

    return (
        <GenericDataGrid
            columns={columns}
            rows={mappedRows}
            />
    )
}