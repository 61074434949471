import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridProps} from "@mui/x-data-grid/internals";

function GenericDataGrid(props: DataGridProps): JSX.Element {
    return (
        <Box
            sx={{
              flex: 1,
              overflow: "auto"  
            }}
            >
            <DataGrid autoHeight={true} {...props} />
        </Box>
        
    );
}

export { GenericDataGrid }