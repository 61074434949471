import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { fields } from "./fields";

export function AdminPrintToolbar(): JSX.Element {
    return (
        <GridToolbarContainer>
            <GridToolbarExport
                csvOptions={{
                    escapeFormulas: true,
                    fields: fields,
                    includeHeaders: true,
                    utf8WithBom: true,
                }}
                printOptions={{
                    copyStyles: true,
                    fields: fields,
                    hideToolbar: true,
                    hideFooter: true,
                }}
                />
        </GridToolbarContainer>
    );
}