import React from "react";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useCreateAuditContext } from "../../context";
import { WritebackRadioButtonIcon } from "./icon";

type WritebackRadioButtonProps = {
    primary: React.ReactNode
    secondaryText: string
    value: "true" | "false"
}

export function WritebackRadioButton({ primary, secondaryText, value }: WritebackRadioButtonProps): JSX.Element {
    const { writebackAtCompletion, toggleWritebackAtCompletion } = useCreateAuditContext();

    const isSelectedMemo = React.useMemo((): boolean => {
        const writebackAtCompletionStr = writebackAtCompletion ? "true" : "false";
        return writebackAtCompletionStr === value;
    }, [writebackAtCompletion, value]);

    const onClickButton = (): void => {
        const booleanValue = value === "true" ? true : false;
        toggleWritebackAtCompletion(booleanValue);
    }

    return (
        <ListItemButton
            onClick={onClickButton}
            selected={isSelectedMemo}
            sx={{
                "&.Mui-selected": {
                    border: "2px solid #0D47A1",
                    borderRadius: 2
                }
            }}
            >
            <ListItemIcon>
                <WritebackRadioButtonIcon value={isSelectedMemo} />
            </ListItemIcon>
            <ListItemText
                primary={primary}
                secondary={secondaryText}
                />
        </ListItemButton>  
    );
}