import React from "react";
import { useVinLabelContext } from "./context";
import { VehicleInformation } from "types/dsv/print";
import { columns } from "./dataGridColumns";
import { useDsvPrintSetupContext } from "../../../dsv/print/context";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { Button, Stack } from "@mui/material";

type MappedRow = {
    id: number
    vehicle: VehicleInformation
}

export function VinLabelTemplateView() {
    const { vehicleInformationList } = useDsvPrintSetupContext();
    const { selectedStyle } = useVinLabelContext();
    const apiRef = useGridApiRef();
    const [mappedRows, setMappedRows] = React.useState<MappedRow[]>([]);
    
    React.useEffect(() => {
        setMappedRows(() => {
            if (!vehicleInformationList) {
                return [];
            }

            return vehicleInformationList.map((value: VehicleInformation, index: number) => {
                return {
                    id: index,
                    vehicle: value,
                };
            });
        });
    }, [vehicleInformationList]);

    const onClickPrint = (): void => {
        if (!apiRef || !apiRef.current) {
            return;
        }

        apiRef.current.exportDataAsPrint({
            hideFooter: true,
            hideToolbar: true,
            includeCheckboxes: false,
        });
    }
    
    return (
        <Stack
            direction="column"
            spacing={2}
            >
            <Button
                onClick={onClickPrint}
                variant="contained"
                >
                Print
            </Button>
            <DataGrid
                apiRef={apiRef}
                columns={columns}
                getRowHeight={() => "auto"}
                rows={mappedRows}
                sx={selectedStyle}
                />
        </Stack>
    );
}