import React from "react";
import { CompleteVarianceDetailsDataGrid } from "./variance";
import { useDspAuditDetailsContext } from "../../context";
import { CompleteBinSummaryDetailsDataGrid } from "./binSummary";

export function CompletedDataGridResolver(): JSX.Element {
    const { selectedView } = useDspAuditDetailsContext();
    const [component, setComponent] = React.useState(<CompleteVarianceDetailsDataGrid />);

    React.useEffect(() => {
        setComponent(() => {
            switch (selectedView) {
                case "variance":
                    return <CompleteVarianceDetailsDataGrid />
                case "bins":
                    return <CompleteBinSummaryDetailsDataGrid />
            }
        });
    }, [selectedView]);

    return (component);
}