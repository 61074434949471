import React from "react";
import { Typography } from "@mui/material";
import { LabelBadge } from "./badge/labelBadge";
import { useDspAuditVarianceContext } from "../../../context";
import { DspAuditVariance } from "types/dsp/audits";

export function PendingVariancesLabel(): JSX.Element {
    const { auditVariances, rescanIds } = useDspAuditVarianceContext();

    const count = React.useMemo((): number => {
        return auditVariances.reduce((count: number, auditVariance: DspAuditVariance) => {
            if (rescanIds.has(auditVariance.lightyearPartId)) {
                return count - 1;
            }

            return count;
        }, auditVariances.length);
    }, [auditVariances, rescanIds]);

    return (
        <LabelBadge value={count}>
            <Typography>Pending Variances</Typography>
        </LabelBadge>
    );
}