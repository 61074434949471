import { GridColDef } from "@mui/x-data-grid";
import { DspAuditBinSimple } from "types/dsp/audits";
import { Utilities } from "../../../../../../../utilities";
import { NewQuantityInput } from "./newQuantityInput";
import { FlagsColumn } from "./flags";
import { IconButton } from "@mui/material";
import { VarianceSelectedCheckbox } from "./checkbox";
import AutorenewIcon from '@mui/icons-material/Autorenew';

export const pendingColumns: GridColDef[] = [
    {
        field: "check",
        filterable: false,
        flex: 0.3,
        headerName: "",
        hideable: false,
        renderCell: (params) => (<VarianceSelectedCheckbox lightyearId={params.row.id} />),
        resizable: false,
        sortable: false,
    },
    {
        field: "partNumber",
        filterable: false,
        flex: 1,
        headerName: "Part Number",
        hideable: false,
        resizable: false,
        sortable: true,
    },
    {
        field: "bins",
        filterable: false,
        flex: 0.5,
        headerName: "Bin",
        hideable: false,
        resizable: false,
        sortable: true,
        valueFormatter: (value: DspAuditBinSimple[]) => value.map((binObj: DspAuditBinSimple) => binObj.binName).join(", ")
    },
    {
        field: "vendor",
        filterable: false,
        flex: 0.5,
        headerName: "Vendor",
        hideable: false,
        resizable: false,
        sortable: true,
    },
    {
        field: "onHandCount",
        filterable: false,
        flex: 0.75,
        headerName: "On Hand Count",
        hideable: false,
        resizable: false,
        sortable: true,
    },
    {
        field: "dollarVariance",
        filterable: false,
        flex: 0.75,
        headerName: "$ Variance",
        hideable: false,
        resizable: false,
        sortable: true,
        valueFormatter: (value: number) => Utilities.displayAsCurrency(value)
    },
    {

        field: "quantityVariance",
        filterable: false,
        flex: 0.75,
        headerName: "Qty Variance",
        hideable: false,
        resizable: false,
        sortable: true,
    },
    {
        field: "scannedCount",
        filterable: false,
        flex: 0.5,
        headerName: "Scanned",
        hideable: false,
        resizable: false,
        sortable: true,
    },
    {
        field: "partsMovement",
        filterable: false,
        flex: 0.5,
        headerName: "Movement",
        hideable: false,
        resizable: false,
        sortable: true,
    },
    {
        field: "newQuantity",
        filterable: false,
        flex: 0.75,
        headerName: "New Qty",
        hideable: false,
        renderCell: (params) => (<NewQuantityInput lightyearId={params.row.id} />),
        resizable: false,
        sortable: false,
    },
    {
        field: "varianceFlags",
        filterable: false,
        flex: 2,
        headerName: "",
        hideable: false,
        renderCell: (params) => (
            <FlagsColumn
                varianceFlags={{
                    onHoldCount: params.row.onHoldCount,
                    openOrderCount: params.row.openOrderCount,
                    hasMultipleBinsAssigned: params.row.hasMultipleBinsAssigned,
                    hasSupersession: params.row.hasSupersessions,
                }}
                />
        ),
        resizable: false,
        sortable: false,
    },
    {
        field: "move",
        filterable: false,
        flex: 0.35,
        headerName: "",
        hideable: false,
        renderCell: (params) => (
            <IconButton onClick={params.row.toggleFilter}>
                <AutorenewIcon />
            </IconButton>
        ),
        resizable: false,
        sortable: false,
    },
    {
        field: "filter",
        filterable: false,
        flex: 1,
        headerName: "",
        hideable: true,
        resizable: false,
        sortable: false,
    }
];