import { Badge } from "@mui/material"

type LabelBadgeProps = {
    children: React.ReactNode
    value: number
}

export function LabelBadge({ children, value }: LabelBadgeProps): JSX.Element {
    return (
        <Badge
            badgeContent={value}
            color="primary"
            max={999}
            sx={{
                "& .MuiBadge-badge": {
                    marginRight: -2.5,
                    top: 13,
                }
            }}
            >
            {children}
        </Badge>
    )
}