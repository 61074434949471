import React from "react";
import { Stack, FormGroup, FormControlLabel, Switch, Button, TextField, Typography } from "@mui/material";
import { useAdminFeatureFlagContext } from "../../../context";
import { adminEditFormInitialState, AdminEditFormReducer } from "./reducer";
import { useNavigate, useParams } from "react-router-dom";
import { FeatureFlagResult } from "types/admin/featureFlags";
import { CenteredFormView } from "../../../../base/format/centeredFormView";
import { AdminCancelButton } from "../../buttons";

export function AdminEditForm(): JSX.Element {
    const { dealerId, locationId } = useParams();
    const { records, updateRecord } = useAdminFeatureFlagContext();
    const navigate = useNavigate();
    const [state, dispatch] = React.useReducer(AdminEditFormReducer, adminEditFormInitialState);

    React.useEffect(() => {
        if (!dealerId || !locationId) {
            return;
        }

        try {
            const record = records.find((value: FeatureFlagResult) => value.dealerId === dealerId && value.locationId === Number(locationId));

            if (!record) {
                throw new Error("Record not found");
            }

            dispatch({ type: "SET_RECORD", payload: {
                id: record.id,
                canUseDsp: record.canUseDsp,
                canUseDspAudits: record.canUseDspAudits,
                canFindDspAuditSupersessions: record.canFindDspAuditSupersessions,
                canUseDsv: record.canUseDsv,
            }});
        } catch (err) {
            console.error("Unable to retrieve record to edit", err);
        }
    }, [dealerId, locationId, records]);

    const onChangeCanUseDsp = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: "CHANGE_CAN_USE_DSP", payload: e.target.checked });
    }

    const onChangeCanUseDspAudits = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: "CHANGE_CAN_USE_DSP_AUDITS", payload: e.target.checked });
    }

    const onChangeCanFindDspAuditSupersessions = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: "CHANGE_CAN_FIND_DSP_AUDIT_SUPERSESSIONS", payload: e.target.checked });
    }

    const onChangeCanUseDsv = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: "CHANGE_CAN_USE_DSV", payload: e.target.checked });
    }

    const onClickSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        updateRecord(state)
            .then(() => navigate("/admin"))
            .catch((err) => {
                console.error("Unable to update feature flag record", err);
            });
    }
    
    return (
        <CenteredFormView>
            <Stack
                direction="column"
                spacing={2}
                >
                <Typography variant="h5">Edit Customer</Typography>
                <TextField
                    disabled={true}
                    label="Dealer ID"
                    value={dealerId}
                    />
                <TextField
                    label="Location ID"
                    type="number"
                    value={locationId}
                    />
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={state.canUseDsv}
                                inputProps={{ 'aria-label': 'controlled' }}
                                onChange={onChangeCanUseDsv}
                                />
                        }
                        label="Vehicle Scanning"
                        />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={state.canUseDsp}
                                inputProps={{ 'aria-label': 'controlled' }}
                                onChange={onChangeCanUseDsp}
                                />
                        }
                        label="Parts Scanning"
                        />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={state.canUseDspAudits}
                                inputProps={{ 'aria-label': 'controlled' }}
                                onChange={onChangeCanUseDspAudits}
                                />
                        }
                        label="New Audits"
                        />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={state.canFindDspAuditSupersessions}
                                inputProps={{ 'aria-label': 'controlled' }}
                                onChange={onChangeCanFindDspAuditSupersessions}
                                />
                        }
                        label="Supersession Variance Flag"
                        />
                </FormGroup>
                <Button
                    color="primary"
                    onClick={onClickSubmit}
                    type="submit"
                    variant="contained"
                    >
                    Save Changes
                </Button>
                <AdminCancelButton />
            </Stack>
        </CenteredFormView>
    );
}