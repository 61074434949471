import { GridColDef } from "@mui/x-data-grid";
import { Utilities } from "../../../../../../../../utilities";

const columns: GridColDef[] = [
    {
        field: "id",
        headerName: "ID",
        hideable: true,
        width: 90
    },
    {
        field: "partNumber",
        flex: 1,
        headerName: "Part Number",
    },
    {
        field: "bins",
        flex: 1,
        headerName: "Bins",
        valueFormatter: (value: string[]) => value.join(", ")
    },
    {
        field: "partCost",
        flex: 1,
        headerName: "Part Cost",
        valueFormatter: (value: number) => Utilities.displayAsCurrency(value)
    },
    {
        field: "expected",
        flex: 1,
        headerName: "Expected"
    },
    {
        field: "scanned",
        flex: 1,
        headerName: "Scanned",
    },
    {
        field: "quantityVariance",
        flex: 1,
        headerName: "Qty Variance",
    },
    {
        field: "dollarVariance",
        flex: 1,
        headerName: "$ Variance",
        valueFormatter: (value: number) => Utilities.displayAsCurrency(value)
    },
    {
        field: "newQuantity",
        flex: 1,
        headerName: "New Quantity",
        valueFormatter: (value: number | null) => `${value || "-"}`
    },
    {
        field: "override",
        flex: 1,
        headerName: "Override",
        valueFormatter: (value: boolean) => value ? "Yes" : "No"
    },
    {
        field: "reconciled",
        flex: 1,
        headerName: "Reconciled",
        valueFormatter: (value: boolean) => value ? "Yes" : "No"
    }
];

export { columns }