import React from "react";
import { useDspAuditVarianceContext } from "../../../../context";
import { Checkbox } from "@mui/material";

export type VarianceSelectedCheckboxProps = {
    lightyearId: number
}

export function VarianceSelectedCheckbox({ lightyearId }: VarianceSelectedCheckboxProps): JSX.Element {
    const { selectedPending, selectedRescan, selectedTab, togglePendingSelected, toggleRescanSelected } = useDspAuditVarianceContext();

    const isSelected = React.useMemo((): boolean => {
        switch (selectedTab) {
            case "pending":
                return selectedPending.has(lightyearId);
            case "rescans":
                return selectedRescan.has(lightyearId);
            case "mismatch":
                return false;
        }

    }, [lightyearId, selectedPending, selectedRescan, selectedTab]);

    const onClickCheckbox = (): void => {
        switch (selectedTab) {
            case "pending":
                togglePendingSelected(lightyearId);
                break;
            case "rescans":
                toggleRescanSelected(lightyearId);
                break;
            case "mismatch":
                break;
        }
    }

    return (
        <Checkbox
            checked={isSelected}
            onClick={onClickCheckbox}
            />
    );
}