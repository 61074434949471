import { NavButtons } from "../../../base/navButtons";
import { PrintLabelsSetupButton, PrintVinLabelsButton } from "./buttons";

export function VehiclesNav(): JSX.Element {
    return (
        <NavButtons
            showSetup={true}
            setupPath="/vehicles/setup"
            >
            <PrintLabelsSetupButton />
            <PrintVinLabelsButton />
        </NavButtons>
    );
}