import { SxProps } from "@mui/system";
import { Theme } from "@mui/material";
import { VehiclePrintTemplateCode } from "types/admin/printTemplate";

type VinLabelState = {
    selectedTemplate?: VehiclePrintTemplateCode
    selectedStyle: SxProps<Theme>
}

type VinLabelReducerAction =
    | { type: "SET_LOCATION_CODE", payload: VehiclePrintTemplateCode }
    | { type: "SET_SELECTED_STYLE", payload: SxProps<Theme> }

export const vinLabelInitialState: VinLabelState = {
    selectedTemplate: undefined,
    selectedStyle: {},
};

export function VinLabelReducer(state: VinLabelState, action: VinLabelReducerAction): VinLabelState {
    switch (action.type) {
        case "SET_LOCATION_CODE":
            return {
                ...state,
                selectedTemplate: action.payload
            };
        case "SET_SELECTED_STYLE":
            return {
                ...state,
                selectedStyle: action.payload
            };
    }
}