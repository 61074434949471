import React from "react";
import { Button } from "@mui/material";
import { CreateAuditForm } from "./form";
import { GenericModal } from "../../../../../base/modal";
import { CreateAuditContextProvider } from "./form/context";

function CreateAuditButton(): JSX.Element {
    const [showModal, setShowModal] = React.useState(false);

    const hideModalCallback = React.useCallback(() => {
        setShowModal(false);
    }, []);

    const onClickCreateAudit = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        
        setShowModal(true);
    }

    return(
        <>
            <Button
                color="primary"
                onClick={onClickCreateAudit}
                variant="contained"
                >
                Start Audit
            </Button>
            <GenericModal
                showModal={showModal}
                hideModalCallback={hideModalCallback}
                dialogTitle="Create Audit"
                maxWidth={false}
                >
                <CreateAuditContextProvider>
                    <CreateAuditForm />
                </CreateAuditContextProvider>
            </GenericModal>
        </>
        
    );
}

export { CreateAuditButton }