import React from "react";
import { GenericDataGrid } from "../../../../../../base/dataGrid";
import { useDspAuditDetailsContext } from "../../../context";
import { columns } from "./columns";
import { BinsMappedRow } from "./types";
import { DspAuditBinReport } from "types/dsp/audits";
import { Utilities } from "../../../../../../../utilities";

export function CompleteBinSummaryDetailsDataGrid(): JSX.Element {
    const { binReports } = useDspAuditDetailsContext();
    const [mappedRows, setMappedRows] = React.useState<BinsMappedRow[]>([]);

    React.useEffect(() => {
        setMappedRows(binReports.map((value: DspAuditBinReport, index: number) => {
            return {
                id: index,
                bin: value.bin,
                partNumbers: value.partNumbers,
                totalParts: value.totalParts,
                dollarValue: value.dollarValue,
                quantityVariance: value.quantityVariance,
                dollarVariance: value.dollarVariance,
                timeToScan: Utilities.displayDistanceBetweenDates(value.submittedDate, value.createdDate),
                reconciled: value.reconciled,
            };
        }));
    }, [binReports]);

    return (
        <GenericDataGrid
            columns={columns}
            columnVisibilityModel={{
                id: false
            }}
            rows={mappedRows}
            />
    );
}