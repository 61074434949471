import React from "react";
import { GenericDataGrid } from "../../../../../../base/dataGrid";
import { useDspAuditDetailsContext } from "../../../context";
import { columns } from "./columns";
import { VarianceMappedRow } from "./types";
import { DspAuditVarianceReport } from "types/dsp/audits";

export function CompleteVarianceDetailsDataGrid(): JSX.Element {
    const { auditStatus, auditVariances } = useDspAuditDetailsContext();
    const [mappedRows, setMappedRows] = React.useState<VarianceMappedRow[]>([]);

    React.useEffect(() => {
        setMappedRows(auditVariances.filter((value: DspAuditVarianceReport) => value.expected !== value.scanned)
            .map((value: DspAuditVarianceReport, index: number) => {
                return {
                    id: index,
                    partNumber: value.partNumber,
                    bins: value.bins,
                    partCost: value.partCost,
                    expected: value.expected,
                    scanned: value.scanned,
                    quantityVariance: value.newQuantity
                        ? value.newQuantity - value.expected
                        : value.scanned - value.expected,
                    dollarVariance: value.newQuantity
                        ? (value.newQuantity - value.expected) * value.partCost
                        : (value.scanned - value.expected) * value.partCost,
                    newQuantity: value.newQuantity ? value.newQuantity : null,
                    override: value.newQuantity !== null && value.scanned !== value.newQuantity,
                    reconciled: value.newQuantity !== null
                };
            }));
    }, [auditVariances, auditStatus]);

    return (
        <GenericDataGrid
            columns={columns}
            columnVisibilityModel={{
                id: false
            }}
            rows={mappedRows}
            />
    );
}