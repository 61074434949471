import { Button, Chip } from "@mui/material";
import { useDspAuditDetailsContext } from "../../../../../context";

export function BinsButton(): JSX.Element {
    const { binReports, changeSelectedView } = useDspAuditDetailsContext();

    const onClickBinsButton = (): void => {
        changeSelectedView("bins");
    }

    return (
        <Button onClick={onClickBinsButton}>
            Bins&nbsp;<Chip label={binReports.length} />
        </Button>
    );
}