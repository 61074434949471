import React from "react";
import { useVinLabelContext } from "../context";
import { FiveByFourTemplate, ThreeByThreeTemplate, ThreeByTwoTemplate } from "../templates";
import { VehicleInformation } from "types/dsv/print";

type VinLabelTemplateResolverProps = {
    vehicle: VehicleInformation
}

export function VinLabelTemplateResolver({ vehicle }: VinLabelTemplateResolverProps): JSX.Element {
    const { selectedTemplate } = useVinLabelContext();
    const [template, setTemplate] = React.useState<JSX.Element>(<></>);

    React.useEffect(() => {
        setTemplate(() => {
            switch (selectedTemplate) {
                case "vehicle3by3basic":
                    return <ThreeByThreeTemplate vehicle={vehicle} />
                case "vehicle3by2basic":
                    return <ThreeByTwoTemplate vehicle={vehicle} />
                case "vehicle5by4basic":
                    return <FiveByFourTemplate vehicle={vehicle} />
                case undefined:
                    return <></>;
            }
        });
    }, [selectedTemplate, vehicle]);

    return (template);
}