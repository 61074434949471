import { GetPrintTemplateResponse } from "types/admin/printTemplate";

type DsvLabelSetupState = {
    printTemplates: GetPrintTemplateResponse[]
    savedTemplate?: number
    selectedTemplate?: number
}

type DsvLabelSetupReducerAction =
    | { type: "SET_TEMPLATES", payload: GetPrintTemplateResponse[] }
    | { type: "SELECT_TEMPLATE", payload: number}
    | { type: "SET_LOCATION_OPTION", payload: number }

export const dsvLabelSetupInitialState: DsvLabelSetupState = {
    printTemplates: [],
    savedTemplate: undefined,
    selectedTemplate: undefined,
};

export function DsvLabelSetupReducer(state: DsvLabelSetupState, action: DsvLabelSetupReducerAction): DsvLabelSetupState {
    switch (action.type) {
        case "SET_TEMPLATES":
            return {
                ...state,
                printTemplates: action.payload
            };
        case "SELECT_TEMPLATE":
            return {
                ...state,
                selectedTemplate: action.payload
            };
        case "SET_LOCATION_OPTION":
            return {
                ...state,
                savedTemplate: action.payload
            };
    }
}