import { GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { Link } from "react-router-dom";
import { DeleteButton } from "../../../../base/crud";

type ColumnsProps = {
    deleteVehicleAudit: (deleteId: number) => Promise<void>
}

export function columns({ deleteVehicleAudit }: ColumnsProps): GridColDef[] {
    return [
        {
            field: "id",
            headerName: "ID",
            hideable: false,
            renderCell: (params) => (
                <Link to={`/vehicles/audits/${params.value}`}>{params.value}</Link>
            ),
            width: 90
        },
        {
            field: "auditor",
            flex: 1,
            headerName: "Auditor",
            hideable: false
        },
        {
            field: "scanCount",
            flex: 1,
            headerName: "Scan Count",
            hideable: false
        },
        {
            field: "completedDate",
            flex: 1,
            headerName: "Completed Date",
            hideable: false,
            valueFormatter: (value: Date) => {
                return `${moment(value).format("MM/DD/YYYY h:mm:ss a")}`
            }
        },
        {
            field: "deleteId",
            filterable: false,
            headerName: "",
            hideable: false,
            renderCell: (params) => (
                <DeleteButton
                    deleteId={params.value}
                    deleteAction={deleteVehicleAudit}
                    />
            ),
            sortable: false,
            width: 100,
        }
    ];
}