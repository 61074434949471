import { GridColDef } from "@mui/x-data-grid";
import { Utilities } from "../../../../../../../../utilities";

export const columns: GridColDef[] = [
    {
        field: "bin",
        flex: 1,
        headerName: "Bin",
        hideable: false
    },
    {
        field: "partNumbers",
        flex: 1,
        headerName: "Part Numbers",
        hideable: false
    },
    {
        field: "totalParts",
        flex: 1,
        headerName: "Total Parts",
        hideable: false
    },
    {
        field: "dollarValue",
        flex: 1,
        headerName: "Dollar Value",
        hideable: false,
        valueFormatter: (value: number) => Utilities.displayAsCurrency(value)
    },
    {
        field: "quantityVariance",
        flex: 1,
        headerName: "Quantity Variance",
        hideable: false
    },
    {
        field: "dollarVariance",
        flex: 1,
        headerName: "Dollar Variance",
        hideable: false,
        valueFormatter: (value: number) => Utilities.displayAsCurrency(value)
    },
    {
        field: "timeToScan",
        flex: 1,
        headerName: "Time To Scan",
        hideable: false,
        renderCell: (params) => {
            if (params.row.binStatus === 0) {
                return (<span>-</span>);
            }

            return (<span>{params.value}</span>);
        }
    },
    {
        field: "reconciled",
        flex: 1, 
        headerName: "Reconciled",
        hideable: false,
        valueFormatter: (value: boolean) => value ? "Yes" : "No"
    },
];