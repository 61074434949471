import { Card, CardContent, Stack } from "@mui/material";
import { TemplateProps } from "../props";
import { FiveByFourTopForm } from "./top";
import { FiveByFourBottomForm } from "./bottom";

export function FiveByFourTemplate({ vehicle }: TemplateProps): JSX.Element {
    return (
        <Card
            sx={{
                height: "5in",
                width: "4in"
            }}
            >
            <CardContent className="labelVin">
                <FiveByFourTopForm vehicle={vehicle} />
                <Stack direction="row">
                    <FiveByFourBottomForm vehicle={vehicle} />
                    <FiveByFourBottomForm vehicle={vehicle} />
                </Stack>
                <Stack direction="row">
                    <FiveByFourBottomForm vehicle={vehicle} />
                    <FiveByFourBottomForm vehicle={vehicle} />
                </Stack>
            </CardContent>
        </Card>
    );
}