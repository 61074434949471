type CreateAuditStateType = {
    auditName: string
    writebackAtCompletion: boolean
}

type CreateAuditReducerAction =
    | { type: "UPDATE_NAME", payload: string }
    | { type: "TOGGLE_WRITEBACK", payload: boolean }

const createAuditInitialState: CreateAuditStateType = {
    auditName: `Audit ${new Date().toLocaleDateString()}`,
    writebackAtCompletion: false, // Realtime!
};

function CreateAuditReducer(state: CreateAuditStateType, action: CreateAuditReducerAction): CreateAuditStateType {
    switch (action.type) {
        case "UPDATE_NAME":
            return {
                ...state,
                auditName: action.payload,
            };
        case "TOGGLE_WRITEBACK":
            return {
                ...state,
                writebackAtCompletion: action.payload,
            };
    }
}

export {
    createAuditInitialState,
    CreateAuditReducer
}