import { GridCallbackDetails, GridColDef, GridColumnVisibilityModel, GridFilterModel, GridPaginationModel, GridSlotsComponent, GridSortingInitialState } from "@mui/x-data-grid";
import { FilterableDataGridProvider } from "./context";
import { FilteredDataGrid } from "./dataGrid";

export type PreDefinedFilterModel = {
    label: string
    filterModel: GridFilterModel
}

export type GenericDataGridFilterableProps = {
    columns: GridColDef[]
    filterModels?: PreDefinedFilterModel[]
    columnVisibilityModel?: GridColumnVisibilityModel
    initialStatePagination?: Partial<GridPaginationModel> 
    initialStateSorting?: GridSortingInitialState
    rows: any[]
    pageSizeOptions?: number[]
    slots?: Partial<GridSlotsComponent>
    onColumnVisibilityModelChange?: (model: GridColumnVisibilityModel, details: GridCallbackDetails) => void
}

export function GenericDataGridFilterable(props: GenericDataGridFilterableProps): JSX.Element {
    return (
        <FilterableDataGridProvider
            columns={props.columns}
            columnVisibilityModel={props.columnVisibilityModel}
            filterModels={props.filterModels}
            initialStatePagination={props.initialStatePagination}
            initialStateSorting={props.initialStateSorting}
            rows={props.rows}
            pageSizeOptions={props.pageSizeOptions}
            slots={props.slots}
            onColumnVisibilityModelChange={props.onColumnVisibilityModelChange}
            >
            <FilteredDataGrid />
        </FilterableDataGridProvider>
    );
}