import { Stack, Typography } from "@mui/material";
import { DsvLabelSetupContextProvider } from "./context";
import { BackToAuditHistoryButton } from "../audit/backToAuditHistoryButton";
import { LabelsSetupForm } from "./form";

export function LabelsSetupView(): JSX.Element {
    return (
        <DsvLabelSetupContextProvider>
            <Stack
                direction="column"
                spacing={1}
                >
                <BackToAuditHistoryButton />
                <Typography variant="h5">Vehicle Label Default</Typography>
                <Typography variant="body2">Choose which label you would like as your default (only choose one at a time)</Typography>
                <LabelsSetupForm />
            </Stack>
        </DsvLabelSetupContextProvider>
    );
}