import React from "react";
import { useApiService } from "../../../../base/providers";
import { DspAudit, GetDspAuditsResponse } from "types/dsp/audits";
import { AuditReducer, auditsInitialState } from "./reducer";

type DspAuditContextType = {
    audits: DspAudit[]
    cancelCreateAudit: (auditId: number) => Promise<void>
    reloadData: () => void
}

const DspAuditContext = React.createContext<DspAuditContextType | undefined>(undefined);

type DspAuditProviderProps = {
    children: React.ReactNode
}

function DspAuditProvider(props: DspAuditProviderProps): JSX.Element {
    const { apiService } = useApiService();
    const [state, dispatch] = React.useReducer(AuditReducer, auditsInitialState);

    const getAllAuditsCallback = React.useCallback(() => {
        apiService.dsp.getAllAudits()
            .then((response: GetDspAuditsResponse) => dispatch({ type: "SET_AUDITS", payload: response }))
            .catch((err) => console.error("Unable to get audits", err));
    }, [apiService]);

    const cancelCreateAuditCallback = React.useCallback((auditId: number): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            try {
                await apiService.dsp.cancelAudit(auditId);
                resolve();
            } catch (err) {
                reject(err);
            }
        })
    }, [apiService]);

    React.useEffect(() => {
        getAllAuditsCallback();
    }, [getAllAuditsCallback])

    return (
        <DspAuditContext.Provider
            value={{
                audits: state.audits,
                cancelCreateAudit: cancelCreateAuditCallback,
                reloadData: getAllAuditsCallback,
            }}
            >
            {props.children}
        </DspAuditContext.Provider>
    );
}

function useDspAuditContext(): DspAuditContextType {
    const context = React.useContext(DspAuditContext);

    if (!context) {
        throw new Error("useDspAuditContext must be used within a DspAuditProvider");
    }

    return context;
}

export { DspAuditProvider, useDspAuditContext }