import { SxProps, Theme } from "@mui/material";

export const printTemplateWebStyle: SxProps<Theme> = {
    "#label-container": {
        border: "1px solid gray",
        padding: "1em",
    },
    "#label-container .label": {
        border: "1px solid black",
        height: "1in",
        margin: "0 0 1em 0",
        padding: "0.3em",
        width: "2.84in",
    },
    "#label-container .label:last-child": {
        marginBottom: 0,
    },
    "#label-container .label .binLabelBarcode": {
        margin: 0,
    },
    "#label-container .label .binLabelText": {
        fontSize: "1.4em",
        fontWeight: "bold",
        margin: "-0.9em 0 0 0.5em",
        zIndex: 10,
    },
    "#label-container .label .stockLabelTopRow": {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        margin: 0,
        zIndex: 10,
    },
    "#label-container .label .stockLabelTopRow .stockLabelPartNumberText": {
        fontSize: "1.3em",
        fontWeight: "bold",
    },
    "#label-container .label .stockLabelBarcode": {
        margin: "-0.65em 0 0 0",
    },
    "#label-container .label .stockLabelDescriptionText": {
        margin: "-1em 0 0 0",
        zIndex: 10,
    },
    "@media print": {
        "body": {
            fontWeight: 400,
            imageRendering: "-webkit-optimize-contrast",
            printColorAdjust: "exact",
            "-webkit-print-color-adjust": "exact",
        },
        "*": {
            fontSmooth: "never",
            textRendering: "optimizeLegibility",
            "-webkit-font-smoothing": "none",
        },
        "@page": {
            height: "1in",
            width: "2.84in",
        },
        "#label-container": {
            border: 0,
            margin: 0,
            padding: 0,
        },
        "#label-container .label": {
            border: 0,
            margin: 0,
            pageBreakInside: "avoid",
            pageBreakAfter: "always",
        },
        "#label-container .label:last-child": {
            pageBreakAfter: "auto",
        },
        "#label-container .label .binLabelText": {
            fontWeight: "bold",
            margin: "-0.9em 0 0 0.5em",
        },
        "#label-container .label .binLabelBarcode": {
            padding: 0,
        },
        "#label-container .label .stockLabelDescriptionText": {
            margin: "-1.1em 0 0 0",
            padding: 0,
        }
    }
};