import { DspAudit, DspAuditBin, DspAuditBinReport, DspAuditPart, DspAuditVarianceReport, GetDspAuditDetailsResponse } from "types/dsp/audits";

type AuditDetailsStateType = {
    auditBins: DspAuditBin[]
    audit?: DspAudit
    auditParts: DspAuditPart[]
    auditVariances: DspAuditVarianceReport[]
    binReports: DspAuditBinReport[]
    canFinishAudit: boolean
    selectedBins: Set<string>
    selectedView: "variance" | "bins"
}

type AuditDetailsReducerAction = 
    | { type: "CHANGE_SINGLE_BIN_SELECTION", payload: string }
    | { type: "SET_AUDIT_DETAILS", payload: GetDspAuditDetailsResponse }
    | { type: "SET_SELECTED_VIEW", payload: "variance" | "bins" }

const auditDetailsInitialState: AuditDetailsStateType = {
    auditBins: [],
    auditParts: [],
    auditVariances: [],
    binReports: [],
    canFinishAudit: false,
    selectedBins: new Set<string>(),
    selectedView: "variance"
};

function AuditDetailsReducer(state: AuditDetailsStateType, action: AuditDetailsReducerAction): AuditDetailsStateType {
    switch (action.type) {
        case "CHANGE_SINGLE_BIN_SELECTION":
            const newSelectedBins = new Set(state.selectedBins);
            const binName = action.payload;

            if (newSelectedBins.has(binName)) {
                newSelectedBins.delete(binName);
            } else {
                newSelectedBins.add(binName);
            }

            return {
                ...state,
                selectedBins: newSelectedBins
            };
        case "SET_AUDIT_DETAILS":
            return {
                ...state,
                auditBins: action.payload.auditBins,
                audit: action.payload.audit,
                auditParts: action.payload.auditParts,
                auditVariances: action.payload.auditVariances,
                binReports: action.payload.binReports,
                canFinishAudit: action.payload.auditBins.every((value: DspAuditBin) => value.binStatus === 2)
            };
        case "SET_SELECTED_VIEW":
            return {
                ...state,
                selectedView: action.payload,
            };
    }
}

export {
    auditDetailsInitialState,
    AuditDetailsReducer
}