import { Box, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { VinLabelTemplateResolver } from "../resolver";

export const columns: GridColDef[] = [
    {
        align: "center",
        headerAlign: "center",
        disableColumnMenu: true,
        field: "label",
        flex: 1,
        renderHeader: () => (
            <Typography
                className="printPreviewHeader"
                variant="h5"
                >
                Print Preview
            </Typography>
        ),
        renderCell: (params) => (
            <Box className="gridCardCenterer">
                <VinLabelTemplateResolver vehicle={params.row.vehicle} />
            </Box>
        ),
        sortable: false,
    }
];