import { Typography, Box, Card, CardContent, Divider } from "@mui/material";
import Barcode from "react-barcode";
import { TemplateProps } from "./props";

export function ThreeByThreeTemplate({ vehicle }: TemplateProps): JSX.Element {
    return (
        <Card
            sx={{
                height: "3in",
                width: "3in"
            }}
            >
            <CardContent className="labelVin">
                <Typography
                    className="labelVinIdentificationNumber"
                    >
                    {vehicle.vin}
                </Typography>
                <Box className="labelVinBarcode">
                    <Barcode
                        displayValue={false}
                        height={75}
                        renderer="svg"
                        value={vehicle.vin}
                        width={1.0}
                        />
                </Box>
                <Box className="labelVinDescriptionContainer">
                    <Typography
                        align="left"
                        className="labelVinDescription"
                        >
                        {`Stock Number: ${vehicle.stockNumber}`}
                    </Typography>
                    <Divider />
                    <Typography
                        align="left"
                        className="labelVinDescription"
                        >
                        {vehicle.color}
                    </Typography>
                    <Typography
                        align="left"
                        className="labelVinDescription"
                        >
                        {vehicle.year}
                    </Typography>
                    <Typography
                        align="left"
                        className="labelVinDescription"
                        >
                        {vehicle.make}
                    </Typography>
                    <Typography
                        align="left"
                        className="labelVinDescription"
                        >
                        {vehicle.model}
                    </Typography>
                    <Typography
                        align="left"
                        className="labelVinDescription"
                        >
                        {vehicle.bodyStyle}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
}