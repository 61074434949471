import React from "react";
import { Button, Stack, TextField } from "@mui/material";
import { useDsvPrintSetupContext } from "../context";

export function PrintForm(): JSX.Element {
    const { stockNumberCsvStr, submit, updateStockNumberInput } = useDsvPrintSetupContext();

    const onChangeStockNumbers = (e: React.ChangeEvent<HTMLInputElement>): void => {
        updateStockNumberInput(e.target.value);
    }

    const onClickSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        submit();
    }

    return (
        <Stack
            autoComplete="off"
            component="form"
            direction="column"
            spacing={2}
            >        
            <TextField
                id="stockNumbers"
                label="Stock Number(s)"
                multiline={true}
                onChange={onChangeStockNumbers}
                placeholder="Example: A77533, R31101"
                rows={4}
                value={stockNumberCsvStr}
                />
            <Button
                color="primary"
                onClick={onClickSubmit}
                type="submit"
                variant="outlined"
                >
                Generate Preview
            </Button>
        </Stack>
    );
}