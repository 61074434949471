import React from "react";
import { Button, Divider, FormControl, FormLabel, Stack, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCreateAuditContext } from "./context";
import { FormRadioGroup } from "./radioGroup";

function CreateAuditForm(): JSX.Element {
    const navigate = useNavigate();
    const { auditName, updateAuditName, submitForm } = useCreateAuditContext();

    const onChangeAuditName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        updateAuditName(e.target.value || "");
    }

    const onClickSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        submitForm()
            .then((auditId) => navigate(`/parts/audit/selection/${auditId}`))
            .catch((err) => {
                console.error("Unable to create audit", err);
            });
    }

    return (
        <Stack
            autoComplete="off"
            component="form"
            direction="column"
            spacing={2}
            sx={{
                paddingTop: 2
            }}
            >
            <TextField
                id="auditName"
                label="Audit Name"
                onChange={onChangeAuditName}
                value={auditName}
                />
            <Divider />
            <FormControl>
                <FormLabel>Method</FormLabel>
                <FormRadioGroup />
            </FormControl>
            <Button
                color="primary"
                onClick={onClickSubmit}
                type="submit"
                variant="contained"
                >
                Create
            </Button>
        </Stack>
    );
}

export { CreateAuditForm }