import { GetPrintInstructionsRespose, VehicleInformation } from "types/dsv/print";

type DsvPrintSetupState = {
    vehicleInformation: VehicleInformation[]
    formState: {
        stockNumbersCsvStr: string
    }
}

type DsvPrintSetupReducerAction =
    | { type: "SET_VEHICLE_INFORMATION", payload: GetPrintInstructionsRespose }
    | { type: "SET_STOCK_NUMBER_CSV", payload: string}

export const dsvPrintSetupInitialState: DsvPrintSetupState = {
    vehicleInformation: [],
    formState: {
        stockNumbersCsvStr: "",
    },
};

export function DsvPrintSetupReducer(state: DsvPrintSetupState, action: DsvPrintSetupReducerAction): DsvPrintSetupState {
    switch (action.type) {
        case "SET_VEHICLE_INFORMATION":
            return {
                ...state,
                vehicleInformation: action.payload.foundStock
            };
        case "SET_STOCK_NUMBER_CSV":
            return {
                ...state,
                formState: {
                    stockNumbersCsvStr: action.payload
                }
            };
    }
}