import { GridColDef } from "@mui/x-data-grid";
import { Utilities } from "../../../../../../../utilities";
import { DspAuditBinSimple } from "types/dsp/audits";
import { VarianceSelectedCheckbox } from "./checkbox";
import { IconButton } from "@mui/material";
import AutorenewIcon from '@mui/icons-material/Autorenew';

export const rescanColumns: GridColDef[] = [
    {
        field: "check",
        filterable: false,
        flex: 0.3,
        headerName: "",
        hideable: false,
        renderCell: (params) => (<VarianceSelectedCheckbox lightyearId={params.row.id} />),
        resizable: false,
        sortable: false,
    },
    {
        field: "bins",
        filterable: false,
        flex: 1,
        headerName: "Bins",
        hideable: false,
        valueFormatter: (value: DspAuditBinSimple[]) => value.map((binObj: DspAuditBinSimple) => binObj.binName).join(", "),
        resizable: false,
        sortable: true,
    },
    {
        field: "partNumber",
        filterable: false,
        flex: 1,
        headerName: "Part Number",
        hideable: false,
        resizable: false,
        sortable: true,
    },
    {
        field: "vendor",
        filterable: false,
        flex: 1,
        headerName: "Vendor",
        hideable: false,
        resizable: false,
        sortable: true,
    },
    {
        field: "dollarVariance",
        filterable: false,
        flex: 1,
        headerName: "$ Variance",
        hideable: false,
        valueFormatter: (value: number) => Utilities.displayAsCurrency(value),
        resizable: false,
        sortable: true,
    },
    {

        field: "quantityVariance",
        filterable: false,
        flex: 1,
        headerName: "Qty Variance",
        hideable: false,
        resizable: false,
        sortable: true,
    },
    {
        field: "move",
        filterable: false,
        flex: 0.225,
        headerName: "",
        hideable: false,
        renderCell: (params) => (
            <IconButton onClick={params.row.toggleFilter}>
                <AutorenewIcon />
            </IconButton>
        ),
        resizable: false,
        sortable: false,
    },
    {
        field: "filter",
        filterable: false,
        flex: 1,
        headerName: "",
        hideable: true,
        resizable: false,
        sortable: false,
    }
];