import { Stack, Typography } from "@mui/material";
import { PrintForm } from "./form";
import { BackToAuditHistoryButton } from "../audit/backToAuditHistoryButton";
import { VinLabelTemplateView } from "../../base/print/vinLabel";
import { DsvPrintSetupContextProvider } from "./context";
import { VinLabelContextProvider } from "../../base/print/vinLabel/context";

function DsvPrintView(): JSX.Element {
    return (
        <DsvPrintSetupContextProvider>
            <Stack
                direction="column"
                spacing={2}
                >
                <BackToAuditHistoryButton />
                <Typography variant="h4">Print Labels</Typography>
                <PrintForm />
                <VinLabelContextProvider>
                    <VinLabelTemplateView />
                </VinLabelContextProvider>
            </Stack>
        </DsvPrintSetupContextProvider>
    );
}

export { DsvPrintView }