import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export function PrintLabelsSetupButton(): JSX.Element {
    const navigate = useNavigate();

    const onClickLabelsSetup = (): void => {
        navigate("/vehicles/labelsetup")
    }

    return (
        <Button
            onClick={onClickLabelsSetup}
            variant="outlined"
            >
            Labels Setup
        </Button>
    );
}